<template>
  <div>
    <Header titleurl="secondhouse" :iscontactTel="iscontactTel" v-on:closeShow="closeShow"></Header>
    <div class="details" >
      <div class="search">
        <div class="inner">
          <div class="container_breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/secondhouse' }">{{result.cityName}}二手房</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/secondhouse',query:{countyCode:result.screenRegionId}}">{{result.countyName}}二手房</el-breadcrumb-item>
				<el-breadcrumb-item>{{!!result.communityName?result.communityName:""}}</el-breadcrumb-item>
			</el-breadcrumb>
          </div>
          <div class="title">{{!!result.title?result.title:''}}</div>
        </div>
      </div>
      <div class="inner">
        <div class="overview">
          <div class="img">
            <div class="imgContainer">
              <img :src="posimgs[0].imgUrl" />
            </div>
            <div class="thumbnail">
              <el-button class="pre" @click="pre()" :disabled="isButton" >&lt;</el-button>
              <ul class="smallpic">
                <div class="pos">
                  <li v-for="(item,index) in posimgs" :class="{active: posactive ==index}" :key="index" @click="posimg(index,item)">
                    <img :src="item.imgUrl" />
                  </li>
                </div>
              </ul>
              <el-button class="next" @click="next()" :disabled="isButton">&gt;</el-button>
            </div>
          </div>
          <div class="contents inner">
            <div class="navlink">
              <div class="link">分享此房源</div>
              <div class="link">
                <div>房贷计算器</div>
              </div>
              <div class="link">
                <div>精准评估</div>
              </div>
            </div>
            <div class="price">
              <span class="total">{{result.totalPrice}}</span>
              <span class="unit">
                <span>万</span>
              </span>
              <div class="text">
                <div class="unitPrice">
                  <span class="unitPriceValue">{{result.unitPrice}}<i>元/平米</i></span>
                </div>
                <div class="tax">
                  <span>首付及贷款情况请咨询经纪人</span>
                </div>
              </div>
            </div>
            <div class="houseInfo">
              <div class="room">
                <div class="mainInfo">{{!!result.roomCount?result.roomCount:''}}室{{result.parlourCount}}厅</div>
                <div class="subInfo">{{!!result.direction?result.direction:""}}({{!!result.floorAll?'共'+result.floorAll+'层':''}})</div>
              </div>
              <div class="type">
                <div class="mainInfo">{{!!result.floor?result.floor+'层':''}}</div>
                <div class="subInfo">{{result.decoration?result.decoration:''}}</div>
              </div>
              <div class="area">
                <div class="mainInfo">{{result.area}}平米</div>
				<div class="subInfo">{{result.unitPrice}}元/平米</div>
                <!-- <div class="subInfo">啊啊</div> -->
              </div>
            </div>
            <div class="aroundInfo">
              <div class="titlename">
                <span class="label">小区名称</span>
                <span>{{result.communityName}}</span>
              </div>
              <div class="titlename">
                <span class="label">所在区域</span>
                <span>{{result.cityName}}</span>
              </div>
              <!-- <div class="titlename">
                <span class="label">房源编号</span>
                <span>{{result.house.houseCode}}</span>
              </div> -->
              <div class="titlename">
                <span class="label">更新时间</span>
                <span>{{result.refreshAt}}</span>
              </div>
			  <div class="house-phone">
				<el-button type="danger" @click="callphone()"><i class="el-icon-phone"></i>电话联系他</el-button>
			  </div>
			  <el-dialog
				title=""
				:visible.sync="dialogVisible"
				width="30%">
				<span class="isphone">{{!!result.contactTel?result.contactTel:""}}</span>
			  </el-dialog>
            </div>
          </div>
        </div>
        <div class="m-content inner">
          <div class="box-l">
            <div class="content-house">
              <h2>
                <div class="title">基本信息</div>
              </h2>
              <div class="introContent">
                <div class="base flex">
                  <div class="name"><span class="firstred"></span>基本属性</div>
                  <div class="contents">
                    <ul>
                      <li>
                        <span class="label">房屋户型</span>
                        <p>{{result.roomCount}}室{{result.parlourCount}}厅{{result.bathroomCount}}卫</p>
                      </li>
                      <li>
                        <span class="label">所在楼层</span>
                        <p>{{!!result.floor?result.floor+'层':""}}</p>
                      </li>
                      <li>
                        <span class="label">建筑面积</span>
                        <p>{{result.area}}㎡</p>
                      </li>
                      <li>
                        <span class="label">建筑类型</span>
                        <p>{{result.buildType}}</p>
                      </li>
                      <li>
                        <span class="label">房屋朝向</span>
                        <p>{{result.direction}}</p>
                      </li>
                      <li>
                        <span class="label">建筑结构</span>
                        <p>{{result.buildStruct}}</p>
                      </li>
                      <li>
                        <span class="label">供暖方式</span>
                        <p>{{result.heatMode}}</p>
                      </li>
                      <li>
                        <span class="label">建筑年代</span>
                        <p>{{!!result.buildYear?result.buildYear+"年":""}}</p>
                      </li>
                      <li>
                        <span class="label">配备电梯</span>
                        <p>{{result.elevatorCount>0?'有':result.elevatorCount===0?'无':''}}</p>
                      </li>
					  <li>
                        <span class="label">产权年限</span>
                        <p>{{!!result.ageLimit?result.ageLimit+"年产权":""}}</p>
                      </li>
                    </ul>
                  </div>
                </div>
				<div class="base">
                  <div class="name"><span class="firstred"></span>核心卖点</div>
				  <div>
					  {{result.sellingPoint}}
				  </div>
                </div>
				<div class="base">
                  <div class="name"><span class="firstred"></span>业主心态</div>
				  <div>
					  {{result.ownerMentality}}
				  </div>
                </div>
				<div class="base">
                  <div class="name"><span class="firstred"></span>服务介绍</div>
				  <div>
					  {{result.serviceIntroduction}}
				  </div>
                </div>
                <!-- <div class="base flex">
                  <div class="name">交易属性</div>
                  <div class="contents">
                    <ul>
                      <li>
                        <span class="label">挂牌时间</span>
                        <p :title="result.sellHouse.listingDate">{{result.sellHouse.listingDate}}</p>
                      </li>
                      <li>
                        <span class="label">交易权属</span>
                        <p>商品房</p>
                      </li>
                      <li>
                        <span class="label">上次交易</span>
                        <span>{{result.sellHouse.lastDate}}</span>
						<p :title="result.sellHouse.lastDate">{{result.sellHouse.lastDate}}</p>
                      </li>
                      <li>
                        <span class="label">房屋用途</span>
                        <p>普通住宅</p>
                      </li>
                      <li>
                        <span class="label">房屋年限</span>
                        <p>{{result.sellHouse.houseAttribute}}</p>
                      </li>
                      <li>
                        <span class="label">产权所属</span>
                        <p>非共有</p>
                      </li>
                      <li>
                        <span class="label">抵押信息</span>
                        <p>{{result.sellHouse.mortgage}}</p>
                      </li>
                      <li>
                        <span class="label">房本备件</span>
                        <p>{{result.sellHouse.isPermit===0?"未上传房本照片":result.sellHouse.isPermit===1?"已上传房本照片":""}}</p>
                      </li>
                    </ul>
                  </div>
                </div> -->
                <div class="disclaimer" style="padding-top: 10px">
                  特别提示：本房源所示信息仅供参考，购房时请以该房屋档案登记信息、产权证信息以及所签订合同条款约定为准；本房源公示信息不做为合同条款，不具有合同约束力。
                </div>
                <div class="guarantee">
                  <div class="guarantee_title">该房源享受牛豆豆服务保障</div>
                  <div class="guarantee_img_box">
                    <img src="../../assets/img/peacelive.png" />
                  </div>
                </div>
                <h2>
                  <div class="title">房屋实拍</div>
                </h2>
                <div class="containers">
                  <el-row class="detail-imgs">
				  	<el-image v-for="(url,index) in result.houseImgList" :key="index" :src="url.imgUrl" style="width: 340px; height: 230px" lazy ></el-image>
				  </el-row>
                </div>
              </div>
             	<h2>
					<div class="title">小区简介</div>
				</h2>
              <div class="community_info">
                <ul class="community_info_list">
                  <li class="community_info_item">
                    <span class="name">小区均价</span>
                    <span class="num">{{result.avgPrice}}元/㎡</span>
                  </li>
                  <li class="community_info_item">
                    <span class="name">建筑年代</span>
                    <span class="text">{{!!result.buildYear?result.buildYear+"年":""}}</span>
                  </li>
                  <li class="community_info_item">
                    <span class="name">建筑类型</span>
                    <span class="text">{{result.buildType}} </span>
                  </li>
                  <li class="community_info_item">
                    <span class="name">小区地址</span>
                    <a class="text hidden_text" style=""
                      >{{result.address}}</a
                    >
                  </li>
                  <!-- <li class="community_info_item">
                    <span class="name">在售房源</span>
                    <a href="javascript:" class="house_num"> 36套 在售二手房</a>
                    <a href="javascript:" class="house_num">
                      5 套法拍房在售（低于评估价20%）</a
                    >
                  </li> -->
                </ul>
              </div>
			  <div class="community_img">
				<img v-if="result.communityImg!=''" :src="result.communityImg" width="200" height="130"/>
				<!-- <img :src="posimgs[0].imgUrl" width="200" height="130"/> -->
			  </div>
            </div>
          </div>
        </div>
        <!-- <div>周边配套</div> -->
        
        <div id="containerAmap">
		  <div class="titleamap">街景地图</div>
          <el-amap
			vid="amapsecondhouse"  
			:center="center"
			:zoom="zoom"  
			class="amap-demo"
			:events="events">
				<el-amap-marker v-for="(marker, index) in markers" :position="marker" :key="index"></el-amap-marker>
			</el-amap>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>  
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import {secondinfo} from '@/api/housedetail'
import Cookies from 'js-cookie';
import {getCookieByName} from '@/utils/helper';
var access_token =getCookieByName('access_token');
export default {
  name: 'housedetail',
  components:{
    Header,Footer
  },
  data() {
    const self = this
    return {
	  id:'',
	  contactTel:'',
	  iscontactTel:false,
	  dialogVisible:false,
	  isButton:false,
	  result:{
		  communityInfo:{},
		  floor:{},
		  gisCoord:{},
		  house:{},
		  sellHouse:{},
		  unit:{}
	  },
	  posactive:0,
      posimgs:[{
		imgUrl:require('@/assets/img/jinxiu3.jpg'),
	  }],
	  markers: [],
      center: [113.624763, 34.753257],
      zoom: 15,
	  // address: '',
	  lng: 117.127665,
      lat: 36.679559,
      address:'',
      events: {
        click(e) {
          self.markers = []
          let { lng, lat } = e.lnglat
          self.center = [lng, lat]
          self.markers.push([lng, lat])
          // 这里通过高德 SDK 完成。
          var geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: "all",
          });  
          geocoder.getAddress([lng ,lat], function(status, result) {
            if (status === 'complete' && result.info === 'OK') {
            if (result && result.regeocode) {
              console.log(result)
              self.address = result.regeocode.formattedAddress;
              self.$nextTick();
            }
            }
          });        
        }
      },
    };
  },
  mounted(){
	this.id = this.$route.query.id
	this.details()
  },
  methods: {
	//二手车详情
	details(){
		secondinfo({id:this.id}).then(res=>{
			if(res.data.code===1){
				this.result = res.data.result
				if(res.data.result.houseImgList.length>0){
					this.posimgs = res.data.result.houseImgList
					this.posactive = 0
				}
				if(res.data.result.lnglatAmap!=""){
					let lnglatAmap = res.data.result.lnglatAmap.split(",")
					this.center = lnglatAmap
					this.markers.push(lnglatAmap)
				}
			}else{
				window.history.go(-1)
			}
		})
	},
	//照片向左一个
	pre(){
		this.isButton = true
		const self = this
		if(this.posactive==0){
			this.posactive = this.posimgs.length-1
			this.$(".imgContainer>img").attr("src",this.posimgs[this.posimgs.length-1].imgUrl)
			if(this.posimgs.length<5){
			}else{
				let poslenth = (this.posactive-4)*130
				this.$(".smallpic>.pos").css("left",-poslenth+"px")
			}
		}else{
			this.posimgs.forEach(function(item,i){
				if(self.posactive === i){
					if(self.posactive ===1){
						self.$(".smallpic>.pos").css("left","0")
					}else if(self.posactive < self.posimgs.length-4){
						self.$(".smallpic>.pos").css("left",(parseInt(self.$(".smallpic>.pos").css("left"))+130))
					}
					self.posactive = i-1
					self.$(".imgContainer>img").attr("src",self.posimgs[i-1].imgUrl)
					
				}
			})
		}
		setTimeout(function(){
			self.isButton = false
		},200)
	},
	//照片向右一个
	next(){
		this.isButton = true
		const self = this
		const active = this.posactive
		if(active==this.posimgs.length-1){
			this.posactive = 0
			this.$(".smallpic>.pos").css("left","0")
			this.$(".imgContainer>img").attr("src",self.posimgs[0].imgUrl)
		}else{
			this.posimgs.forEach(function(item,i){
				if(active=== i){
					self.posactive = i+1
					if(active>=4){
						self.$(".smallpic>.pos").css("left",(parseInt(self.$(".smallpic>.pos").css("left"))-130))
					}
					self.$(".imgContainer>img").attr("src",self.posimgs[i+1].imgUrl)
				}
			})
		}
		setTimeout(function(){
			self.isButton = false
		},200)
	},
    posimg(index,val){
      this.posactive = index;
      this.$(".imgContainer>img").attr("src",val.imgUrl)
	},
	//电话联系他  contactTel
	callphone(){
		if(access_token!=undefined){
			this.dialogVisible = true
		}else{
			this.iscontactTel = true
		}
	},
	//header头部传过来关闭登录框的操作
	closeShow(val){
		this.iscontactTel = val
	},
    // housedetail() {
    //   this.$router.push('/secondhouse/housedetail');
    // },
  },
};
</script>
<style scoped>
			.search{
				width: 100%;
				height: 100px;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				border-bottom: 1px solid #f5f5f5;
			}
			.search, .title{
				margin-top: 20px;
				overflow: hidden;/*超出部分隐藏*/
				white-space: nowrap;/*不换行*/
				text-overflow:ellipsis;/*超出部分省略号显示*/
			}
			.inner{
				width: 1200px;
				margin: 0 auto;
			}
			.container_breadcrumb{
				font-size: 14px;
				line-height: 1;
			}
		
			.title{
				color: #2c3e50;
				font-size: 24px;
				font-weight: 700;
			}
			.overview{
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-pack: justify;
				-ms-flex-pack: justify;
				justify-content: space-between;
				width: 100%;
				margin-top: 10px;
			}
			.img .imgContainer{
				width: 710px;
				height: 400px;
				position: relative;
			}
			.img .imgContainer img{
			    width: 710px;
			    height: 400px;
			    position: relative;
			}
			.img .thumbnail{
			    position: relative;
			    margin-top: 8px;
				border: 0px;
			    width: 710px;
			    height: 82px;	
				overflow: hidden;
			}
			.img .thumbnail .pre{
			    position: absolute;
			    top: 0;
				cursor: pointer;
			    line-height: 82px;
			    width: 30px;
				padding: 0;
			    text-align: center;
			    font-size: 20px;
			    color: #cac9c9;
			    opacity: 0.8;
			    filter: alpha(opacity=80);
			    background-color: #000;
			    -webkit-user-select: none;
			    -moz-user-select: none;
			    -ms-user-select: none;
			    user-select: none;	
			}
			.img .thumbnail ul{
			    margin-left: 31px;
			    width: 640px;
			    overflow: hidden;
			    height: 82px;
			    -webkit-box-pack: justify;
			    -ms-flex-pack: justify;
			    justify-content: space-between;
			    position: relative;	
			}
			.img .thumbnail ul .pos{
				left: 0px;
			    width: 10000000000%;
			    display: inline-block;
			    position: absolute;
			    -webkit-transition: all .3s ease;
			    -o-transition: .3s ease all;
			    transition: all .3s ease;	
			}
			.img .thumbnail .next{
			    right: 0;
				position: absolute;
			    top: 0;
				cursor: pointer;
			    line-height: 82px;
			    width: 30px;
				padding: 0;
			    text-align: center;
			    font-size: 20px;
			    color: #cac9c9;
			    opacity: 0.8;
			    filter: alpha(opacity=80);
			    background-color: #000;
			    -webkit-user-select: none;
			    -moz-user-select: none;
			    -ms-user-select: none;
			    user-select: none;		
			}
			.img .thumbnail ul .pos li img{
			    width: 100%;
			    height: 100%;
			}	
			.img .thumbnail ul .pos li{
			    width: 120px;
			    height: 82px;
			    margin: 0 5px;
			    text-align: center;
			    display: inline-block;
			    opacity: .5;
			}
			.img .thumbnail ul .pos .active{
			    opacity: 1;
			}
			.details .overview .contents{
			    width: 380px;
				margin: 0 55px;
			    line-height: 1;
			}
			.detail-imgs .el-image{
				width: 340px;
				margin:0 10px 6px 0;
			}
			.contents .navlink{
			    display: -webkit-box;
			    display: -ms-flexbox;
			    display: flex;
			    -webkit-box-pack: end;
			    -ms-flex-pack: end;
			    justify-content: flex-end;
			}
			.contents .navlink .link{
			    height: 30px;
			    margin-left: 10px;
			    cursor: pointer;
			}
			details a:link{
			    color: #9b9ea0;
			    text-decoration: none;	 
			}
			a{
			    outline: none;	
			}
			.contents .price {
			    position: relative;
			    height: 49px;
			}
			.contents .price .total{
			    display: inline-block;
			    font-family: Tahoma;
			    font-size: 46px;
			    line-height: 46px;
			    color: #e4393c;
			    font-weight: 700;
			    letter-spacing: -1px;
			    max-width: 165px;
			    overflow: hidden;
			    -o-text-overflow: ellipsis;
			    text-overflow: ellipsis;
			    white-space: nowrap;
			}
			.contents .price .unit{
			    display: inline-block;
			    font-size: 16px;
			    color: #e4393c;
			    height: 37px;
			    vertical-align: 6px;
			}
			.contents .price .text{
			    font-size: 12px;
			    color: #333;
			    display: inline-block;
			    margin-left: 15px;
			    vertical-align: 6px;
			}
			.contents .price .text .unitPrice{
			    font-size: 16px;
			    color: #394043;
			}
			.contents .price .text .unitPrice span{
			    font-weight: 700;
			    color: #394043;
			    white-space: nowrap;
			}
			.contents .price .text .tax{
			    margin-top: 11px;
			    color: #394043;
			    font-size: 12px;
			}
			.contents .houseInfo {
			    -webkit-box-sizing: border-box;
			    box-sizing: border-box;
			    margin-top: 22px;
			    width: 100%;
			    border-top: 1px solid #eee;
			    border-bottom: 1px solid #eee;
			    padding: 30px 0;
			    display: -webkit-box;
			    display: -ms-flexbox;
			    display: flex;
			}
			.contents .houseInfo .room,.contents .houseInfo .type, .contents .houseInfo .area{
			    width: 33.33%;
			}
			.contents .houseInfo .mainInfo{
			    font-size: 20px;
			    font-weight: 700;
			    color: #333;
			    overflow: hidden;
			    -o-text-overflow: ellipsis;
			    text-overflow: ellipsis;
			    white-space: nowrap;
			}
			.contents .houseInfo .subInfo{
			    margin-top: 8px;
			    font-size: 12px;
			    color: #394043;
			    overflow: hidden;
			    -o-text-overflow: ellipsis;
			    text-overflow: ellipsis;
			    white-space: nowrap;
			}
			.contents .aroundInfo{
			    padding: 24px 0;
			    line-height: 18px;
			    border-bottom: 1px solid #eee;
			    font-size: 14px;
			}
			.contents .titlename{
			    margin-top: 14px;
			    white-space: nowrap;
			    overflow: hidden;
			    -o-text-overflow: ellipsis;
			    text-overflow: ellipsis;
			}
			.contents .titlename .label{
			    color: #aeb0b1;
			    margin-right: 24px;
			}
			.details .m-content .box-l{
			    width: 710px;
			}
			.details .m-content .box-l .content-house{
			    width: 700px;
			}
			.details .m-content .box-l .content-house h2{
			    padding-top: 55px;
			}
			.details .m-content .box-l .content-house .title{
			    margin-bottom: 25px;
			    font-weight: 700;
			    font-size: 23px;
			    line-height: 23px;
			}
			.details .m-content .box-l .content-house .base{
			    width: 710px;
			    vertical-align: top;
			    border-top: 1px solid #eee;
			    border-bottom: 1px solid #eee;
			    line-height: 22px;
			    padding: 20px 0;
			}
			.details .m-content .box-l .content-house .base .firstred{
				background-color:red;
				width:4px;
				height:10px;
				float:left;
				margin:5px 5px 0 0 ;
			}
			.flex{
				display: flex;
			}
			.details .m-content .box-l .content-house .base .name{
			    width: 188px;
			    text-align: left;
			    color: #999;
			    line-height: 23px;
			}
			.details .m-content .box-l .content-house .base .contents{
			    width: 522px;
			}
			.details .m-content .box-l .content-house .base .contents ul{
			    display: -webkit-box;
			    display: -ms-flexbox;
			    display: flex;
			    -ms-flex-wrap: wrap;
			    flex-wrap: wrap;
			}
			.details .m-content .box-l .content-house .base .contents li{
			    display: -webkit-box;
			    display: -ms-flexbox;
			    display: flex;
			    width: 50%;
			    line-height: 24px;
			    font-size: 14px;
			    color: #394043;
			}
			.details .m-content .box-l .content-house .base .contents li .label{
			    color: #999;
			    margin-right: 30px;
				line-height: 24px;
				font-size: 14px;
				white-space: nowrap;
				font-weight: 500;
				padding: 0;
			}
			.details .m-content .box-l .content-house .base .contents li p{
				overflow: hidden;
				white-space: nowrap;
				text-overflow:ellipsis;
			}
			.details .m-content p{
				margin: 0;
				padding: 0;
			}
			.details .m-content .box-l .content-house .disclaimer{
			    clear: both;
			    color: #b0b3b4;
			    font-size: 12px;
			}
			.details .m-content .box-l .content-house .guarantee{
			    width: 100%;
			    margin-top: 20px;
			    padding: 34px 0 0;
			    border-top: 1px solid #eee;
			}
			.details .m-content .box-l .content-house .guarantee .guarantee_title{
			    width: 100%;
			    font-size: 14px;
			    line-height: 20px;
			    color: #999;
			}
			.details .m-content .box-l .content-house .guarantee .guarantee_img_box{
			    width: 575px;
			    height: 124px;
			    margin-top: 32px;
			    position: relative;
			    overflow: hidden;
			}
			.details .m-content .box-l .content-house .containers{
			    max-height: 1136px;
			    overflow: hidden;
			}
			.details .m-content .box-l .content-house .containers .list{
			    margin: 15px auto 0;
			    width: 710px;
			    text-align: justify;
			    line-height: 0;
			}
			.details .m-content .box-l .content-house .containers .list div:first-child {
			    width: 710px;
			    height: 400px;
			}
			.details .m-content .box-l .content-house .containers .list div{
			    width: 350px;
			    height: 230px;
			    margin-bottom: 10px;
			    display: inline-block;
			    position: relative;
			    text-align: center;
			}
			.details .m-content .box-l .content-house .containers .list div img{
			    max-width: 100%;
			    width: 100%;
			    height: 100%;
			}
			.details .m-content .box-l .content-house .containers .list div span{
			    display: inline-block;
			    position: absolute;
			    bottom: 0;
			    left: 0;
			    line-height: 32px;
			    text-align: center;
			    padding: 0 11px;
			    border-radius: 2px;
			    background-color: rgba(0,0,0,.4);
			    color: #fff;
			    font-size: 12px;
			}
			.details .m-content .box-l .content-house .community_info{
			    width: 500px;
				float: left;
			    overflow: hidden;
			    display: -webkit-box;
			    display: -ms-flexbox;
			    display: flex;
			}
			.details .m-content .box-l .content-house .community_info .community_info_list{
				clear: both;
			    width: 500px;
			    overflow: hidden;
			}
			.details .m-content .box-l .content-house .community_info .community_info_list .community_info_item{
			    width: 100%;
			    overflow: hidden;
			    font-size: 14px;
			    color: #999;
			    line-height: 20px;
			    margin-bottom: 10px;
			}
			.details .m-content .box-l .content-house .community_info .community_info_list .community_info_item a{
				color: #3ea3e9;
				width: 500px;
				overflow: hidden;/*超出部分隐藏*/
				white-space: nowrap;/*不换行*/
				text-overflow:ellipsis;/*超出部分省略号显示*/
			}
			.details .m-content .box-l .content-house .community_info .community_info_list .community_info_item .name{
			    width: 84px;
          		margin-right: 10px;
			}
			.details .m-content .box-l .content-house .community_info .community_info_list .community_info_item .num{
			    color: #ff6767;
			}
			.details .m-content .box-l .content-house .community_img{
				float: left;
			}
			.details .m-content .box-l .content-house .community_img img{
				margin-top: -25px;
				cursor: pointer;
			}
			.house-phone{
				margin-top: 90px;
			}
			.isphone{
				font-size: 20px;
			}
			.house-phone .el-icon-phone{
				color: white;
				font-size: 40px;
				position: absolute;
				margin-left: -70px;
			}
			.house-phone .el-button{
				color: white;
				background-color:  #ff552e;
				width: 300px;
				border-radius: 0%;
				line-height: 45px;
				font-size: 18px;
			}

			#containerAmap{
				clear: both;
				width: 1200px;
				margin-top: 15px;
				height: 500px; 
			}
			#containerAmap .titleamap{
				margin-bottom: 5px;
			}
</style>